import { inject, Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import {
  switchMap,
  forkJoin,
  map,
  of,
  from,
  take,
  concatMap,
  toArray,
} from 'rxjs';
import {
  SchoolStudentModelConverter,
  SchoolStudentModel,
  SchoolStudentDetailsModel,
} from '../shared/models/school-student.model';
import { UserModel, UserModelConverter } from '../shared/models/user.model';
import {
  SchoolTeacherDetailModel,
  SchoolTeacherModel,
  SchoolTeacherModelConverter,
} from '../shared/models/school-teacher.model';
import {
  SchoolTeacherAttendanceModel,
  SchoolTeacherAttendanceModelConverter,
} from '../shared/models/school-teacher-attendance.model';
import {
  SchoolAdminDetailModel,
  SchoolAdminModel,
  SchoolAdminModelConverter,
} from '../shared/models/school-admin.model';
import { SchoolStudentAssessmentModelConverter } from '../shared/models/school-student-assessment.model';
import { SchoolStudentAttendanceModelConverter } from '../shared/models/school-student-attendance.model';
import { SchoolModelCurriculum } from '../shared/models/school.model';

@Injectable({
  providedIn: 'root',
})
export class SuperadminService {
  constructor() {}

  private firestore: Firestore = inject(Firestore);

  fetchAllSchoolsStudents(school_doc_ids: string[]) {
    if (!school_doc_ids || !school_doc_ids.length) {
      return of(
        [] as {
          school_doc_id: string;
          schoolStudents: SchoolStudentDetailsModel[];
        }[]
      );
    }

    return from(school_doc_ids).pipe(
      concatMap((school_doc_id: string) =>
        this.fetchSchoolStudents(school_doc_id).pipe(
          map((schoolStudents: SchoolStudentDetailsModel[]) => ({
            school_doc_id, // Return the school_doc_id along with students data
            schoolStudents,
          }))
        )
      ),
      toArray(), // Collect results into an array after sequential processing
      take(1)
    );

    // const allSchools$ = forkJoin(
    //   school_doc_ids.map((school_doc_id: string) =>
    //     this.fetchSchoolStudents(school_doc_id).pipe(
    //       map((schoolStudents: SchoolStudentDetailsModel[]) => ({
    //         school_doc_id, // Return the school_doc_id along with students data
    //         schoolStudents,
    //       }))
    //     )
    //   )
    // );

    // return allSchools$.pipe(take(1));
  }

  fetchAllSchoolTeachers(school_doc_ids: string[]) {
    if (!school_doc_ids || !school_doc_ids.length) {
      return of(
        [] as {
          school_doc_id: string;
          schoolTeachers: SchoolTeacherDetailModel[];
        }[]
      );
    }

    return from(school_doc_ids).pipe(
      concatMap((school_doc_id: string) =>
        this.fetchSchoolTeachers(school_doc_id).pipe(
          map((schoolTeachers: SchoolTeacherDetailModel[]) => ({
            school_doc_id,
            schoolTeachers,
          }))
        )
      ),
      toArray(), // Collect the results into an array after all sequential execution
      take(1)
    );

    // const allSchools$ = forkJoin(
    //   school_doc_ids.map((school_doc_id: string) =>
    //     this.fetchSchoolTeachers(school_doc_id).pipe(
    //       map((schoolTeachers: SchoolTeacherDetailModel[]) => ({
    //         school_doc_id, // Return the school_doc_id along with students data
    //         schoolTeachers,
    //       }))
    //     )
    //   )
    // );

    // return allSchools$.pipe(take(1));
  }

  fetchAllSchoolAdmins(school_doc_ids: string[]) {
    if (!school_doc_ids || !school_doc_ids.length) {
      return of(
        [] as {
          school_doc_id: string;
          schoolAdmins: SchoolAdminDetailModel[];
        }[]
      );
    }

    return from(school_doc_ids).pipe(
      concatMap((school_doc_id: string) =>
        this.fetchSchoolAdmins(school_doc_id).pipe(
          map((schoolAdmins: SchoolAdminDetailModel[]) => ({
            school_doc_id,
            schoolAdmins,
          }))
        )
      ),
      toArray(), // Collect results after sequential execution
      take(1)
    );

    // const allSchools$ = forkJoin(
    //   school_doc_ids.map((school_doc_id: string) =>
    //     this.fetchSchoolAdmins(school_doc_id).pipe(
    //       map((schoolAdmins: SchoolAdminDetailModel[]) => ({
    //         school_doc_id, // Return the school_doc_id along with students data
    //         schoolAdmins,
    //       }))
    //     )
    //   )
    // );

    // return allSchools$.pipe(take(1));
  }

  fetchSchoolStudents(school_doc_id: string) {
    let studentsQuery = query(
      collection(this.firestore, 'school_student').withConverter(
        SchoolStudentModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    let schoolStudents$ = collectionData(studentsQuery);

    return schoolStudents$.pipe(
      take(1),
      switchMap((students: SchoolStudentModel[]) => {
        if (students.length) {
          return forkJoin(
            students.map((student: SchoolStudentModel) =>
              this.fetchUserDetails(student.user_id).pipe(
                map((userDoc) => {
                  if (userDoc.exists()) {
                    const userDetails = userDoc.data() as UserModel;
                    return {
                      student_doc: student,
                      student_details: userDetails,
                    } as SchoolStudentDetailsModel;
                  } else {
                    return {
                      student_doc: student,
                      student_details: {},
                    } as SchoolStudentDetailsModel;
                  }
                })
              )
            )
          );
        } else {
          return of([] as SchoolStudentDetailsModel[]);
        }
      })
    );
  }

  fetchSchoolAdmins(school_doc_id: string) {
    let adminsQuery = query(
      collection(this.firestore, 'school_admin').withConverter(
        SchoolAdminModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    let schoolAdmins$ = collectionData(adminsQuery);

    return schoolAdmins$.pipe(
      take(1),
      switchMap((admins: SchoolAdminModel[]) => {
        if (admins.length) {
          return forkJoin(
            admins.map((admin: SchoolAdminModel) =>
              this.fetchUserDetails(admin.user_id).pipe(
                map((userDoc) => {
                  if (userDoc.exists()) {
                    const userDetails = userDoc.data() as UserModel;
                    return {
                      admin_doc: admin,
                      admin_details: userDetails,
                    } as SchoolAdminDetailModel;
                  } else {
                    return {
                      admin_doc: admin,
                      admin_details: {},
                    } as SchoolAdminDetailModel;
                  }
                })
              )
            )
          );
        } else {
          return of([] as SchoolAdminDetailModel[]);
        }
      })
    );
  }

  fetchSchoolTeachers(school_doc_id: string) {
    let teacherQuery = query(
      collection(this.firestore, 'school_teacher').withConverter(
        SchoolTeacherModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false),
      where('school_id', '==', school_doc_id)
    );

    let schoolteachers$ = collectionData(teacherQuery);

    return schoolteachers$.pipe(
      take(1),
      switchMap((teachers: SchoolTeacherModel[]) => {
        if (teachers.length) {
          return forkJoin(
            teachers.map((teacher: SchoolTeacherModel) =>
              this.fetchUserDetails(teacher.user_id).pipe(
                switchMap((userDoc) => {
                  const userDetails = userDoc.exists()
                    ? (userDoc.data() as UserModel)
                    : ({} as UserModel);

                  return this.fetchTeacherAttendance(
                    school_doc_id,
                    teacher.user_id
                  ).pipe(
                    map((attendanceDocs: SchoolTeacherAttendanceModel[]) => {
                      const attendance =
                        attendanceDocs.length > 0 ? attendanceDocs : [];

                      let schoolTeacherDetails: SchoolTeacherDetailModel = {
                        teacher_details: userDetails,
                        teacher_doc: teacher,
                        teacherAttendance: attendance,
                      };

                      return schoolTeacherDetails;
                    })
                  );
                })
              )
            )
          );
        } else {
          return of([] as SchoolTeacherDetailModel[]);
        }
      })
    );
  }

  fetchTeacherAttendance(school_doc_id: string, teacher_id: string) {
    let teacherAttendanceQuery = query(
      collection(this.firestore, `school_teacher_attendance`).withConverter(
        SchoolTeacherAttendanceModelConverter
      ),
      where('teacher_id', '==', teacher_id),
      where('school_id', '==', school_doc_id)
    );
    return collectionData(teacherAttendanceQuery).pipe(take(1));
  }

  fetchAllSchoolAssessmentsAndAttendance(
    queries: { school_doc_id: string; curriculum: SchoolModelCurriculum }[]
  ) {
    // // Create an array of observables that fetch assessments and attendance for each school
    // const observables = queries.map((query) => {
    //   let school_id: string = query.school_doc_id;
    //   const assessments$ = this.fetchSchoolAssessments(
    //     query.school_doc_id,
    //     query.curriculum
    //   );
    //   const attendance$ = this.fetchSchoolAttendance(query.school_doc_id);
    //   // Use forkJoin to wait for both assessments and attendance to be fetched
    //   return forkJoin([assessments$, attendance$]).pipe(
    //     map(([schoolAssessments, schoolAttendance]) => ({
    //       school_id,
    //       schoolAssessments,
    //       schoolAttendance,
    //     }))
    //   );
    // });
    // // Return an observable that emits an array of all results
    // return forkJoin(observables);

    if (!queries || !queries.length) {
      return of(
        [] as {
          school_id: string;
          schoolAssessments: []; // Replace 'any' with your appropriate type
          schoolAttendance: []; // Replace 'any' with your appropriate type
        }[]
      );
    }

    return from(queries).pipe(
      concatMap((query) => {
        const school_id = query.school_doc_id;
        const assessments$ = this.fetchSchoolAssessments(
          query.school_doc_id,
          query.curriculum
        );
        const attendance$ = this.fetchSchoolAttendance(query.school_doc_id);

        // Fetch assessments and attendance sequentially
        return forkJoin([assessments$, attendance$]).pipe(
          map(([schoolAssessments, schoolAttendance]) => ({
            school_id,
            schoolAssessments,
            schoolAttendance,
          }))
        );
      }),
      toArray(), // Collect the results into an array after all requests are completed
      take(1)
    );
  }

  fetchSchoolAssessments(school_id: string, curriculum: SchoolModelCurriculum) {
    const schoolAssessmentsQuery = query(
      collection(this.firestore, `school_student_assessment`).withConverter(
        SchoolStudentAssessmentModelConverter
      ),
      where('school_id', '==', school_id),
      where('curriculum.end_month', '==', curriculum.end_month),
      where('curriculum.start_month', '==', curriculum.start_month),
      where('curriculum.start_year', '==', curriculum.start_year),
      where('curriculum.end_year', '==', curriculum.end_year)
    );
    return collectionData(schoolAssessmentsQuery).pipe(take(1));
  }

  fetchSchoolAttendance(school_id: string) {
    const schoolAttendanceQuery = query(
      collection(this.firestore, `school_student_attendance`).withConverter(
        SchoolStudentAttendanceModelConverter
      ),
      where('school_id', '==', school_id)
    );
    return collectionData(schoolAttendanceQuery).pipe(take(1));
  }

  fetchUserDetails(doc_id: string) {
    let userDocRef = doc(this.firestore, `users/${doc_id}`).withConverter(
      UserModelConverter
    );

    return from(getDoc(userDocRef)).pipe(take(1));
  }

  fetchStudentsTest() {
    let studentsQuery = query(
      collection(this.firestore, 'school_student').withConverter(
        SchoolStudentModelConverter
      ),
      where('active', '==', true),
      where('deleted', '==', false)
    );

    return collectionData(studentsQuery);

    let schoolStudents$ = collectionData(studentsQuery);

    // return schoolStudents$.pipe(
    //   take(1),
    //   switchMap((students: SchoolStudentModel[]) => {
    //     if (students.length) {
    //       return forkJoin(
    //         students.map((student: SchoolStudentModel) =>
    //           this.fetchUserDetails(student.user_id).pipe(
    //             map((userDoc) => {
    //               if (userDoc.exists()) {
    //                 const userDetails = userDoc.data() as UserModel;
    //                 return {
    //                   student_doc: student,
    //                   student_details: userDetails,
    //                 } as SchoolStudentDetailsModel;
    //               } else {
    //                 return {
    //                   student_doc: student,
    //                   student_details: {},
    //                 } as SchoolStudentDetailsModel;
    //               }
    //             })
    //           )
    //         )
    //       );
    //     } else {
    //       return of([] as SchoolStudentDetailsModel[]);
    //     }
    //   })
    // );
  }
}
